const gnav = () => {
    if (!document.querySelector(".js-gnav_trigger")) {
        return;
    }

    const body = document.body;

    document.querySelector(".js-gnav_trigger").addEventListener("click", () => {
        const size = Math.max(window.innerWidth, window.innerHeight);
        document.documentElement.style.setProperty("--overlapSize", `${size * 2.2}px`);

        body.classList.add("-lock");
        body.classList.add("-gnav_active");

        //スクロールリセット
        document.querySelector(".l-gnav").scrollTo(0, 0);
    });

    document.querySelector(".js-gnav_close").addEventListener("click", () => {
        body.classList.remove("-lock");
        body.classList.add("-gnav_out");
        body.classList.remove("-gnav_active");

        setTimeout(() => {
            body.classList.remove("-gnav_out");
        }, 1300);
    });
};

export { gnav };
