//functions
import { setInteractive } from "./interactive/set_interactive.es6";
import { gnav } from "./functions/gnav.es6";
import { modal } from "./functions/modal.es6";
import { imgModal } from "./functions/img_modal.es6";
import { setFitScreen } from "./functions/setFitScreen.es6";
import { onRouteChange } from "./functions/on_routechange.es6";

//classes
import News from "./classes/news.es6";
import Scroll from "./classes/scroll.es6";
export default class Main {
    constructor() {
        this.init();
    }

    init() {
        this.news = new News();
        this.scroll = new Scroll();

        setInteractive();
        gnav();
        modal();
        imgModal();
        setFitScreen();
        onRouteChange();
    }
}
