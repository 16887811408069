const imgModal = () => {
    const openbuttons = document.querySelectorAll(".js-img_modal_open");
    const closebuttons = document.querySelectorAll(".js-img_modal_close");
    const modals = document.querySelectorAll(".js-img_modal");
    const wrapper = document.querySelector(".js-picwrapper");

    [...openbuttons].forEach((el) => {
        el.addEventListener("click", (e) => {
            e.preventDefault();

            const src = el.getAttribute("data-imgsrc");
            const tgtmodal = document.querySelector(".js-img_modal");
            const img = document.createElement("img");
            img.src = src;

            wrapper.appendChild(img);
            tgtmodal && tgtmodal.classList.add("-active");
            document.body.classList.add("-lock");
        });
    });

    [...closebuttons].forEach((el) => {
        el.addEventListener("click", () => {
            [...modals].forEach((el) => el.classList.remove("-active"));
            document.body.classList.remove("-lock");

            //画像domを消去
            while (wrapper.firstChild) {
                wrapper.removeChild(wrapper.firstChild);
            }
        });
    });
};

export { imgModal };
