import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
export default class Scroll {
    constructor() {
        this.init();
    }

    init() {
        this.set();
        this.smoothScroll();
    }

    set() {
        const revealElm = document.querySelectorAll(".js-scrollreveal");

        revealElm.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: "top 80%",
                onEnter: (self) => {
                    el.classList.add("-reveal");
                },
            });
        });

        if (document.querySelector(".interlude_bottom__line")) {
            ScrollTrigger.create({
                trigger: ".interlude_bottom", //アニメーションが始まるトリガーとなる要素
                start: "top bottom",
                onEnter: () => {
                    document.querySelector(".interlude_bottom__line").classList.add("-reveal");
                },
            });
        }
    }

    smoothScroll() {
        const triggers = document.querySelectorAll(".js-smoothscroll");

        triggers.forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                const hash = el.getAttribute("href");
                const offset = window.innerWidth <= 768 ? window.innerWidth * 0.3 : 200 * (window.innerWidth / 1920);
                gsap.to(window, { duration: 1, scrollTo: { y: hash, offsetY: offset }, ease: "circ.inOut" });
            });
        });
    }
}
