const onRouteChange = () => {
    const routechangeElm = [...document.querySelectorAll(".js-routechange_elm")];
    const i_elm = [...document.querySelectorAll(".c-interactive_elm")];
    const scroller_x = [...document.querySelectorAll(".js-scroller_x")];

    routechangeElm.forEach((el) => {
        el.addEventListener("click", (e) => {
            e.preventDefault();

            //円がはみだせるようにoverflow-xを解除する
            scroller_x.forEach((el) => {
                const x = el.scrollLeft;
                el.style.overflow = "visible";
                el.style.transform = `translate(-${x}px,0)`;
            });

            i_elm.forEach((el) => {
                const rect = el.getBoundingClientRect();
                const out_screen = rect.top + rect.height;

                //console.log(out_screen);

                el.style.transition = `all 0.6s 0s cubic-bezier(0.85, 0, 0.15, 1)`;
                el.style.top = `${-1 * out_screen}px`;
            });

            //ページ遷移の場合はページ遷移させる
            if (el.getAttribute("href")) {
                setTimeout(() => {
                    const url = el.getAttribute("href");

                    //target blankの場合別タブ起動
                    if (el.getAttribute("target")) {
                        if (!window.open(url)) {
                            location.href = url;
                        } else {
                            window.open(url);
                        }
                    } else {
                        location.href = url;
                    }
                }, 600);
            }

            //遷移後に戻す（バックフォーワードキャッシュ対策
            setTimeout(() => {
                i_elm.forEach((el) => {
                    el.style.transition = `none`;
                    el.style.top = `0`;
                });
                scroller_x.forEach((el) => {
                    el.style.overflowX = "scroll";
                    el.style.transform = `translate(0,0)`;
                });
            }, 1000);
        });
    });
};

export { onRouteChange };
