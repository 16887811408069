export default class News {
    constructor() {
        this.newslist = window.news || {};
        this.init();
    }

    init() {
        this.eventBind();
    }

    eventBind() {
        this.setDetailBtn();
        this.toggleShow();
        this.setCloseBtn();
    }

    setNews(newsIndex = 0) {
        if (this.newslist.articles.length === 0) {
            return;
        }

        const news_body = document.querySelector(".js-news__body");
        const news_date = document.querySelector(".js-news__date");
        const news_ttl = document.querySelector(".js-news__ttl");

        news_body.innerHTML = this.newslist.articles[newsIndex].body;
        news_date.innerHTML = this.newslist.articles[newsIndex].pubDate;
        news_ttl.innerHTML = this.newslist.articles[newsIndex].title;
    }

    clearNews() {
        const news_body = document.querySelector(".js-news__body");
        const news_date = document.querySelector(".js-news__date");
        const news_ttl = document.querySelector(".js-news__ttl");

        news_body.innerHTML = "";
        news_date.innerHTML = "";
        news_ttl.innerHTML = "";
    }

    setDetailBtn() {
        const newsbtn = [...document.querySelectorAll(".js-news_detail")];

        newsbtn.forEach((el) => {
            el.addEventListener("click", () => {
                const newsIndex = el.getAttribute("data-newsindex") || 0;
                this.setNews(parseInt(newsIndex));
            });
        });
    }

    setCloseBtn() {
        const closebtn = [...document.querySelectorAll(".js-modal_close")];

        closebtn.forEach((el) => {
            el.addEventListener("click", () => {
                this.clearNews();
            });
        });
    }

    toggleShow() {
        const detailbtn = [...document.querySelectorAll(".js-news_detail")];
        const backbtn = [...document.querySelectorAll(".js-news_back")];

        detailbtn.forEach((el) => {
            el.addEventListener("click", () => {
                document.querySelector(".js-news_content[data-content='content']").classList.add("-active");
                document.querySelector(".js-news_content[data-content='list']").classList.remove("-active");
            });
        });

        backbtn.forEach((el) => {
            el.addEventListener("click", () => {
                document.querySelector(".js-news_content[data-content='content']").classList.remove("-active");
                document.querySelector(".js-news_content[data-content='list']").classList.add("-active");
            });
        });
    }
}
