const setInteractive = () => {
    const parent = [...document.querySelectorAll(".js-interactive_elm")];
    const scrollerX = [...document.querySelectorAll(".js-scrollerX")];

    // スクロール追従
    let targetScrollY = 0; // 本来のスクロール位置
    let currentScrollY = 0; // 線形補間を適用した現在のスクロール位置
    let scrollOffsetY = 0; // 上記2つの差分

    // 開始と終了をなめらかに補間する関数
    const lerp = (start, end, multiplier) => {
        return (1 - multiplier) * start + multiplier * end;
    };

    const updateScroll = () => {
        // スクロール位置を取得
        targetScrollY = document.documentElement.scrollTop;
        // リープ関数でスクロール位置をなめらかに追従
        currentScrollY = lerp(currentScrollY, targetScrollY, 0.1);
        scrollOffsetY = targetScrollY - currentScrollY;
    };

    parent.forEach((el) => {
        const elm = document.createElement("div");
        const obj = document.createElement("div");
        const wrap = document.createElement("div");
        const size = el.getAttribute("data-size");
        obj.classList.add("obj");
        wrap.classList.add("wrap");
        elm.classList.add("c-interactive_elm");
        wrap.appendChild(obj);
        elm.appendChild(wrap);

        if (size) {
            wrap.classList.add(`-${size}`);
        }

        el.appendChild(elm);
    });

    const objs = [...document.querySelectorAll(".c-interactive_elm")];
    const prxElm = [...document.querySelectorAll(".c-interactive_prx")];

    const loop = () => {
        updateScroll();

        objs.forEach((el) => {
            const obj = el.querySelector(".obj");
            obj.style.transform = `translate(0, ${scrollOffsetY / 10}px) skew(0,${scrollOffsetY / 40}deg)`;
        });

        prxElm.forEach((el) => {
            el.style.transform = `translate(0, ${scrollOffsetY / 10}px) skew(0,${scrollOffsetY / 40}deg)`;
        });

        requestAnimationFrame(loop);
    };

    loop();
};

export { setInteractive };
