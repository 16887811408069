import gsap from 'gsap';


const modal = () => {
    const openbuttons = document.querySelectorAll(".js-modal_open");
    const closebuttons = document.querySelectorAll(".js-modal_close");
    const modals = document.querySelectorAll(".js-modal");

    [...openbuttons].forEach((el) => {
        el.addEventListener("click", (e) => {
            e.preventDefault();
            const delay = el.getAttribute("data-delay");
            const modalID = el.getAttribute("data-modal");
            const tgtmodal = document.querySelector(`.js-modal[data-modal=${modalID}]`);

            const open = () => {
                tgtmodal && tgtmodal.classList.add("-active");
                document.body.classList.add("-lock");
                gsap.set('.js-modal', {scrollTop:0});
            };

            if (delay) {
                setTimeout(open, delay);
            } else {
                open();
            }
        });
    });

    [...closebuttons].forEach((el) => {
        el.addEventListener("click", () => {
            [...modals].forEach((el) => el.classList.remove("-active"));
            document.body.classList.remove("-lock");
        });
    });


    [...document.querySelectorAll('.js-modal-smooth')].forEach($elem=>{

        $elem.addEventListener('click', e =>{
            e.preventDefault();
            const $target = document.querySelector($elem.getAttribute('href'));
            if(!$target) return false;
            
            const scrolled = document.querySelector('.js-modal.-active').scrollTop;
            const adjust = parseInt($target.getAttribute('data-adjust'));
            const targetAdjust = adjust ? adjust : 0;
            const targetPos = $target.getBoundingClientRect().top + scrolled - targetAdjust;
            gsap.to('.js-modal.-active', {duration:0.5, scrollTop:targetPos, ease:'power2.out'});
        });
    });
};

export { modal };
